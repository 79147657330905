@import '../index.scss';

#bm {
  @include media-breakpoint-down(md) {
    .bm-About_coverImage {
      position: relative;
      padding: 0;
      margin: 0;
    }

    .bm-About_summaryContainer {
      position: absolute;
      max-width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
      text-align: center;
      bottom: 0;
    }

    .bm-Music_playlist {
      width: 100%;
    }

    .bm-Music_item {
      margin-left: 0;
      margin-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 95%;
      overflow: visible !important;
    }

    .bm-Music_itemContainer {
      width: 100vw;
      display: flex;
      justify-content: center;
    }

    .slide.selected {
      min-width: 400px;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-About_coverImage {
      margin-bottom: 3rem;
    }
    
    .bm-About_summaryContainer {
      position: relative;
    }

    .bm-Music_playlist {
      width: 75%;
    }

    .bm-Music_item {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .bm-Music_itemContainer {
      width: 100%;
      min-width: 400px;
      margin-right: 2rem;
    }

    .bm-Music_image--scale:hover {
      transform: scale(1.1);
    }
  }

  .bm-Music__header_image1 {
    margin-top: -75px;
    background: linear-gradient( rgba(153, 102, 255, 0.97), rgba(153, 102, 255, 0.9), rgba(153, 102, 255, 0.9)), url('../assets/images/drake.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 20vh;
  }

  .bm-Music_eventDate {
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .bm-Music_carousel {
    width: 100%;
    display: flex;
    justify-content: center;

    .slide {
      background-color: #fff;
    }
  }

  .bm-Music_image--scale {
    transition: all .4s ease-in-out;
  }

  // .slider {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   width: 100vw;
  // }
}