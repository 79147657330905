
.bm-MediaObject {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bm-MediaObject__imageContainer {
  margin-right: 16px;
}

.bm-MediaObject__textContainer {
  margin-right: 16px;
  flex-wrap: wrap;
  text-align: left;
  overflow: hidden;
  max-width: 100%;
}

.bm-MediaObject_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  width: 100%;
  white-space: pre-wrap;
}

.bm-MediaObject__subtext {
  color: #7e7e7e;
}