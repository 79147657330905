@import '../index.scss';

#bm {
  .bm-PostPreview {
    width: 100%;
  }

  .bm-PostPreview_image {
    width: 40%;
    margin-right: 3rem;
    margin-bottom: 1rem;
  }
  
  .bm-PostPreview_text {
    width: 55%;
  }

  @include media-breakpoint-down(md) {
    .bm-PostPreview {
      justify-content: center;
    }

    .bm-PostPreview_image {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
    
    .bm-PostPreview_text {
      text-align: center;
      width: 100%;
    }
  }
}