@import '../index.scss';

#bm {
  @include media-breakpoint-down(md) {
    .bm-About_coverImage {
      position: relative;
      padding: 0;
      margin: 0;
    }

    .bm-About_summaryContainer {
      position: absolute;
      max-width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
      text-align: center;
      bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-About_coverImage {
      margin-bottom: 3rem;
    }
    
    .bm-About_summaryContainer {
      position: relative;
    }
  }

  .bm-About_header {
    background: linear-gradient( rgba(153, 102, 255, 0.97), rgba(153, 102, 255, 0.9), rgba(153, 102, 255, 0.9)), url('../assets/images/kehlani.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
}