@import '../index.scss';

#bm {
  @include media-breakpoint-down(md) {
    .bm-Music_playlist {
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-Music_playlist {
      width: 100%;
    }
  }
}