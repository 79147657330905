@import '../index.scss';

.bm-Blog_header {
  background: linear-gradient( rgba(153, 102, 255, 0.97), rgba(153, 102, 255, 0.9), rgba(153, 102, 255, 0.9)), url('../assets/images/masego.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.bm-Blog_postContainer {
  width: 100%;
}


@include media-breakpoint-down(md) {
  .bm-Blog_postContainer {
    width: 100%;
  }
}