@import '../index.scss';

#bm {
  .bm-Post {
    width: 90%;
    margin-right: 5rem;

    img, figure, iframe {
      margin-bottom: 2rem;
      // max-width: 600px;
      width: 100%;
    }

    iframe {
      min-height: 30rem;
    }

    .bm-Post_body {
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-right: 0;
      justify-content: center;

      img {
        width: 100%;
      }

      .bm-Post_body {
        width: 100%;
      }
    }

    li {
      margin-bottom: 2rem;
    }

    p,li,b {
      font-size: 1.1rem;
      line-height: 2rem;
    }

    i {
      font-size: 0.85rem;
    }
  }

  .bm-Post_relatedPost_title {
    font-weight: 600;
  }

  @include media-breakpoint-down(md) {
    .bm-Post_wrapper {
      flex-wrap: wrap;
    }
  }
}