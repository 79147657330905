@import '../index.scss';

#bm {
  .bm-Footer {
    background-color: #000;
    width: 100%;
    height: auto;
  }

  .bm-Footer__container {
    min-width: 60%;
  }


  @include media-breakpoint-down(sm) {
    .bm-Footer__container {
      justify-content: center !important;
      margin-bottom: 3rem;
    }
  }

  @include media-breakpoint-up(md) {
    .bm-Footer__container {
      justify-content: space-between !important;
    }
  }

  .bm-Footer__social_link {
    color: #fff;
    transition: ease 250ms;
  }

  .bm-Footer__social_link:hover {
    color: #96f;
  }

  .bm-Footer__social_link:focus {
    color: #96f;
  }
}