@import '../index.scss';

#bm {
  .bm-ViewResult_actionButton {
    margin: 0.25rem;
    min-width: 25%;
  }

  @include media-breakpoint-down(md) {
    .bm-ViewResult_actionButton {
      margin-bottom: 0.75rem;
      width: 100%;
    }
  }
}