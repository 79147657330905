#bm {
  .btn {
    max-height: 45px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  // .btn-secondary {
  //   border: 0.5px solid #e2e2e2;
  //   color: inherit;
  // }
}