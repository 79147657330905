#bm {
  color: rgb(75, 75, 75);
  display: block;
  width: 100%;

  .container {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

  input {
    border: 0.5px solid #e2e2e2;
  }
}

#bm {
  .bm-container {
    padding-top: 32px;
    height: 100%;
    width: 100%;
  }

  section {
    background-color: #fff;
  }

  .bm-TheApp__header_image {
    color: #fff;
  }

  .bm-TheApp__header_image1 {
    margin-top: -75px;
    background: linear-gradient( rgba(153, 102, 255, 0.94), rgba(153, 102, 255, 0.94)), url('./assets/images/concert-background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
  }

  .bm-TheApp__header_image2 {
    background: linear-gradient( rgba(153, 102, 255, 0.94), rgba(153, 102, 255, 0.75), rgba(153, 102, 255, 0.94)), url('./assets/images/anderson-paak-concert.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
    width: 750px;
  }

  .bm-TheApp__header_image3 {
    background: linear-gradient( rgba(153, 102, 255, 0.94), rgba(153, 102, 255, 0.75), rgba(153, 102, 255, 0.94)), url('./assets/images/childish-gambino-concert.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
    width: 750px;
  }

  .bm-TheApp__header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;
  }

  .bm-center_children {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bm-center_children--vertical {
    display: flex;
    align-items: 'center';
  }

  .bm-center_children--horizontal {
    display: flex;
    justify-content: 'center';
  }

  .bm-IOS__btn {
    background-image: url('./assets/images/ios-app-store.png');
  }

  .bm-text {}

  .bm-text--lightColor {
    color: '#fff';
  }

  .bm-text--darkColor {
    color: '#4b4b4b';
  }
}